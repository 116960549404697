<template>
  <div class="home">
    <!-- <homeHeader/> -->
    <el-container class="home-container">
      <el-aside class="left" width="200px">
        <div class="logo">
          <img src="../../assets/img/logo-big.png" alt="" />
        </div>
        <div class="infos">
          <h4>XZQ的博客</h4>
          <div><span>qq:318587344</span></div>
          <div>
            <!-- <span>邮箱:318587344@qq.com</span> -->
          </div>
        </div>
        <div class="menu">
          <div class="title">菜单</div>
          <div class="menu-list">
            <router-link class="menu-item" to="/home/index">
              <div class="icon-wrap">
                <i class="iconfont icon-shouye"></i>
              </div>
              <div class="name">首页</div>
            </router-link>
            <router-link class="menu-item" to="/home/share">
              <div class="icon-wrap">
                <i class="iconfont icon-fenxiang"></i>
              </div>
              <div class="name">个人分享</div>
            </router-link>
            <router-link class="menu-item" to="/home/product">
              <div class="icon-wrap">
                <i class="iconfont icon-chanpin"></i>
              </div>
              <div class="name">项目作品</div>
            </router-link>
            <router-link class="menu-item" to="/home/video_list">
              <div class="icon-wrap">
                <i class="iconfont icon-shipin-copy"></i>
              </div>
              <div class="name">学习视频</div>
            </router-link>
            <router-link class="menu-item" to="/home/user">
              <div class="icon-wrap">
                <i class="iconfont icon-wenzhang-copy"></i>
              </div>
              <div class="name">用户中心</div>
            </router-link>
              <router-link class="menu-item" to="/home/friendship">
              <div class="icon-wrap">
                <i class="iconfont icon-lianjie"></i>
              </div>
              <div class="name">友情链接</div>
            </router-link>
          </div>
          <!-- <div class="title">产品</div>
          <div class="menu-list">
            <router-link class="menu-item" to="/">
              <div class="icon-wrap">o</div>
              <div class="name">首页</div>
            </router-link>
            <router-link class="menu-item" to="/">
              <div class="icon-wrap">o</div>
              <div class="name">首页</div>
            </router-link>
          </div> -->
          <!-- 下方按钮 -->
          <!-- <div class="menu-list menu-footer">
            <div class="menu-item">
              <el-switch
                class="but"
                v-model="themeType"
                active-color="#3d5aeb"
                inactive-color="#f6f5f8"
              >
              </el-switch>
              <div class="name">首页</div>
            </div>
          </div> -->
        </div>
      </el-aside>
      <!-- 主视图 -->
      <el-main>
        <!-- <transition mode="out-in"> -->
             <router-view></router-view>
        <!-- </transition> -->
      </el-main>
    </el-container>
    <!-- <homefooter/> -->
  </div>
</template>

<script>
// import homeHeader from '../../components/homeHeader.vue'
// import homefooter from '../../components/homeFooter.vue'

export default {
  name: "index",
  // components:{homeHeader,homefooter},
  data() {
    return {
      themeType: true,
    };
  },
};
</script>

<style lang='less' scoped>
.el-breadcrumb {
  padding: 8px 20px;
}

.home {
  width: 100%;
  height: calc(100% - 60px);
  background: #f6f5f8;

  .left {
    margin: 0 20px;
    background: #fff;
    border-left: 1px solid rgb(19, 19, 19);
    box-shadow: 1px 2px 1px rgb(201, 201, 201);
    position: relative;

    .logo {
      width: 70%;
      height: 60px;
      margin-top: 10px;
      padding: 0 20px;
      text-align: center;
      img {
        width: 66px;
        height: 60px;
      }
    }

    .infos {
      margin: 0 !important;
      width: 70%;
      text-align: center;
      height: 60px;
      padding: 5px 15px;
      h4 {
        width: 100%;
      }
      div {
        margin: 0 !important;
        width: 100%;
        font-size: 12px;
        color: rgb(105, 105, 105);
      }
    }

    .menu {
      width: calc(100% - 30px);
      padding: 5px 20px;

      .title {
        padding: 5px 0;
        font-size: 14px;
        color: rgb(146, 146, 146);
      }
      .menu-list {
        display: flex;
        flex-direction: column;
        .menu-item {
          width: 100%;
          padding: 10px;
          // white-space:nowrap; // 文本不换行
          .icon-wrap {
            float: left;
            width: 24px;
            height: 24px;
            font-size: 12px;
            line-height: 24px;
            background: #b9b9b9;
            color: #fff;
            border-radius: 10px;
            text-align: center;
            cursor: pointer; // 显示手指
          }

          .name {
            margin-left: 8px;
            float: left;
            line-height: 24px;
            font-size: 14px;
          }
        }

        .active_color {
          color: #3d59ef !important;

          .icon-wrap{
             background: #3d59ef !important;
          }
        }
      }
      .menu-footer {
        margin-top: 40px;
      }
    }
  }
}

.but {
  float: left;
}

.home-container {
  height: 100%;
}

.el-main {
  padding: 5px 20px;
}

// 过场动画

.v-enter-active {
  animation: changeView 1s;
}
.v-leave-active {
  animation: leaveView 1s;
}

@keyframes enterView {
  from {
    // transform: translateY(100%);
    opacity: 0;
  }
  to {
    // transform: translateY(0);
    opacity: 1;
  }
}

@keyframes leaveView {
  from {
    // transform: translateY(0);
    opacity: 1;
  }
  to {
    // transform: translateY(-100%);
    opacity: 0;
  }
}
</style>